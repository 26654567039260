import { Navigate, Route, Routes } from "react-router-dom";
import { AnalyticsLayout } from "../../Layouts";
import {
  offers,
  partnersDashboard,
  checklistDashboard,
  popularUsageChecklist,
  influencerDashboard,
  geo,
  geoAnalytics,
  screenDiscover,
  articleDashboard,
  screen,
  faqDashboard,
  interestsDashboard,
  contentUsageDashboard,
  discoverSearch,
  checklist,
  offersBySource,
  offersByUsers,
  tools,
  birthPlan,
  engagementDashboard,
  pollAnalytics,
  usersPregnancy,
  users,
  popularContent,
  recommendedContent,
  popularClicks,
  popular,
  mainJourneyDashboard,
  appReviewModal,
  courses,
  loyalty,
  loyaltyGeneral,
  loyaltyComparison,
  bannersDashboard,
  dailyDoula,
  articleContent,
  content,
  contentUsage,
  timestamp,
  loyaltyProfileAndMissions,
  loyaltyLockedContentAndFeatures,
  timeSpentApp,
  timeSpentScreen,
  paidSocialDashboard,
  scrollDepth,
  homeScreen,
} from "../../routes/analyticsRouteConsts";
import DataStudioReporter from "../../components/DataStudio/DataStudioReporter";
import { dashboardLinks } from "..";

export const AnalyticsRoutes = () => (
  <Routes>
    <Route path={`/`} element={<AnalyticsLayout />}>
      <Route
        path={partnersDashboard}
        element={<DataStudioReporter src={dashboardLinks.partnersDashboard} />}
      />
      <Route path={offers}>
        <Route
          path={offersBySource}
          element={<DataStudioReporter src={dashboardLinks.offersBySource} />}
        />
        <Route
          path={offersByUsers}
          element={<DataStudioReporter src={dashboardLinks.offersByUsers} />}
        />
      </Route>

      <Route path={checklist}>
        <Route
          path={checklistDashboard}
          element={
            <DataStudioReporter src={dashboardLinks.checklistDashboard} />
          }
        />
        <Route
          path={popularUsageChecklist}
          element={
            <DataStudioReporter src={dashboardLinks.popularUsageChecklist} />
          }
        />
      </Route>

      <Route
        path={faqDashboard}
        element={<DataStudioReporter src={dashboardLinks.faqReport} />}
      />
      <Route path={geo}>
        <Route
          path={geoAnalytics}
          element={
            <DataStudioReporter src={dashboardLinks.geoAnalyticsReport} />
          }
        />
      </Route>
      <Route
        path={influencerDashboard}
        element={
          <DataStudioReporter src={dashboardLinks.influencerDashboard} />
        }
      />
      <Route
        path={articleDashboard}
        element={<DataStudioReporter src={dashboardLinks.articleDashboard} />}
      />
      <Route
        path={bannersDashboard}
        element={<DataStudioReporter src={dashboardLinks.bannerDashboard} />}
      />
      <Route path={loyalty}>
        <Route
          path={loyaltyGeneral}
          element={<DataStudioReporter src={dashboardLinks.loyaltyGeneral} />}
        />
        <Route
          path={loyaltyComparison}
          element={
            <DataStudioReporter src={dashboardLinks.loyaltyComparison} />
          }
        />
        <Route
          path={loyaltyProfileAndMissions}
          element={
            <DataStudioReporter
              src={dashboardLinks.loyaltyProfileAndMissions}
            />
          }
        />
        <Route
          path={loyaltyLockedContentAndFeatures}
          element={
            <DataStudioReporter
              src={dashboardLinks.loyaltyLockedContentAndFeatures}
            />
          }
        />
      </Route>
      <Route path={screen}>
        <Route
          path={screenDiscover}
          element={
            <DataStudioReporter src={dashboardLinks.screenDiscoverDashboard} />
          }
        />
        <Route
          path={discoverSearch}
          element={
            <DataStudioReporter src={dashboardLinks.discoverSearchDashboard} />
          }
        />
        <Route
          path={scrollDepth}
          element={
            <DataStudioReporter src={dashboardLinks.scrollDepthDashboard} />
          }
        />
        <Route
          path={homeScreen}
          element={
            <DataStudioReporter src={dashboardLinks.homeScreenDashboard} />
          }
        />
      </Route>

      <Route
        path={interestsDashboard}
        element={<DataStudioReporter src={dashboardLinks.interestsDashboard} />}
      />
      <Route
        path={contentUsageDashboard}
        element={
          <DataStudioReporter src={dashboardLinks.contentUsageDashboard} />
        }
      />
      <Route
        path={paidSocialDashboard}
        element={
          <DataStudioReporter src={dashboardLinks.paidSocialDashboard} />
        }
      />
      <Route
        path={mainJourneyDashboard}
        element={
          <DataStudioReporter src={dashboardLinks.mainJourneyDashboard} />
        }
      />
      <Route path={tools}>
        <Route
          path={birthPlan}
          element={
            <DataStudioReporter src={dashboardLinks.birthPlanDashboard} />
          }
        />
      </Route>

      <Route
        path={engagementDashboard}
        element={
          <DataStudioReporter src={dashboardLinks.engagementDashboard} />
        }
      />

      <Route
        path={pollAnalytics}
        element={
          <DataStudioReporter src={dashboardLinks.pollAnalyticsDashboard} />
        }
      />

      <Route path={content}>
        <Route
          path={articleContent}
          element={<DataStudioReporter src={dashboardLinks.articleDashboard} />}
        />
        <Route
          path={contentUsage}
          element={
            <DataStudioReporter src={dashboardLinks.contentUsageDashboard} />
          }
        />
      </Route>

      <Route path={popular}>
        <Route
          path={popularContent}
          element={
            <DataStudioReporter src={dashboardLinks.popularContentDashboard} />
          }
        />
        <Route
          path={popularClicks}
          element={
            <DataStudioReporter src={dashboardLinks.popularClicksDashboard} />
          }
        />
      </Route>

      <Route
        path={recommendedContent}
        element={<DataStudioReporter src={dashboardLinks.recommendedContent} />}
      />

      <Route
        path={appReviewModal}
        element={<DataStudioReporter src={dashboardLinks.appReviewModal} />}
      />

      <Route path={users}>
        <Route
          path={usersPregnancy}
          element={
            <DataStudioReporter src={dashboardLinks.pregnancyUsersDashboard} />
          }
        />
        {/* 
        use this when the child users dashboard is ready
        <Route
          path={usersChild}
          element={
            <DataStudioReporter src={dashboardLinks.childUsersDashboard} />
          }
        /> */}
      </Route>

      <Route
        path={courses}
        element={<DataStudioReporter src={dashboardLinks.courses} />}
      />

      <Route
        path={dailyDoula}
        element={<DataStudioReporter src={dashboardLinks.dailyDoula} />}
      />
      <Route path={timestamp}>
        <Route
          path={timeSpentApp}
          element={<DataStudioReporter src={dashboardLinks.timeSpentApp} />}
        />
        <Route
          path={timeSpentScreen}
          element={<DataStudioReporter src={dashboardLinks.timeSpentScreen} />}
        />
      </Route>
      <Route path="*" element={<Navigate to="/analytics" />} />
    </Route>
  </Routes>
);
